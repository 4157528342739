var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ENDPOINTS } from "../../data/core-data";
import { operationFailed } from "../../errors/error-handler";
import Wortal from "../../index";
import { isValidString } from "../../utils/validators";
import { delayUntilConditionMet } from "../../utils/wortal-utils";
import { AuthAPI } from "../auth-api";
/**
 * Provides user authentication using Xsolla Login API.
 * @hidden
 */
export class AuthXsolla extends AuthAPI {
    //#region Properties
    constructor() {
        super();
        this._xsollaConfig = {
            wortalGameID: 0,
            projectID: 0,
            loginProjectID: "",
            jwtToken: "",
        };
        this._lastOperationID = "";
        this._timeoutID = 0;
        this._getXsollaConfig().catch((error) => {
            Wortal._log.exception("Failed to fetch Xsolla config.", error);
        });
    }
    set _operationID(id) {
        // The login code lifetime is 3 minutes.
        // Set a timer to clear the operationID after 3 minutes so if we attempt to complete the login after that
        // it will fail, and we can inform the player that they need to try again.
        clearTimeout(this._timeoutID);
        this._lastOperationID = id;
        this._timeoutID = window.setTimeout(() => {
            this._lastOperationID = "";
        }, 180000);
    }
    //#endregion
    //#region AuthAPI Implementation
    get token() {
        return this._xsollaConfig.jwtToken;
    }
    set token(value) {
        this._xsollaConfig.jwtToken = value;
    }
    get projectID() {
        return this._xsollaConfig.projectID;
    }
    _loginAsyncImpl(payload) {
        const method = payload.method;
        if (method === "password") {
            return this._loginWithUsernameAsync(payload);
        }
        else if (method === "email") {
            return this._startLoginWithEmailAsync(payload);
        }
        else if (method === "phone") {
            return this._startLoginWithPhoneAsync(payload);
        }
        else {
            return Promise.reject(operationFailed(`Unsupported login method: ${method}`, "_loginAsyncImpl"));
        }
    }
    _confirmAsyncImpl(payload) {
        const method = payload.method;
        if (method === "email") {
            return this._completeLoginWithEmailAsync(payload);
        }
        else if (method === "phone") {
            return this._completeLoginWithPhoneAsync(payload);
        }
        else {
            return Promise.reject(operationFailed(`Unsupported confirmation method: ${method}`, "_confirmAsyncImpl"));
        }
    }
    _logoutAsyncImpl() {
        throw new Error("Method not implemented.");
    }
    _registerAsyncImpl(payload) {
        const method = payload.method;
        if (method === "password") {
            return this._registerWithUsernameAsync(payload);
        }
        else {
            return Promise.reject(operationFailed(`Unsupported registration method: ${method}`, "_registerAsyncImpl"));
        }
    }
    _resetPasswordAsyncImpl(payload) {
        throw new Error("Method not implemented.");
    }
    //#endregion
    //#region Xsolla Login API
    /**
     * Registers a new user with the provided payload. This requires the user to verify their email address after
     * registration. If this is successful, the result will be a pending status, and the game should prompt the user
     * to check their email for a verification link and then display the login screen.
     * @param payload The payload to use for registration.
     * @returns The response from the registration attempt. A successful call will return a pending status.
     * @see https://developers.xsolla.com/api/login/operation/oauth-20-register-new-user/
     * @see https://developers.xsolla.com/api/login/operation/jwt-register-new-user/
     * @private
     */
    _registerWithUsernameAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/register`);
            requestURL.search = params.toString();
            const requestBody = {
                username: payload.username,
                password: payload.password,
                email: payload.email,
            };
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    // This shouldn't be the response as it would mean we've disabled email verification.
                    Wortal._log.warn("User registered without email verification. This is not recommended.");
                    return { status: "success" };
                }
                else if (request.status === 204) {
                    // This is the expected response, as it means the user still needs to verify their email.
                    // The game should prompt the user to check their email for a verification link and then display the login screen.
                    // There is no operationID for this action.
                    return { status: "pending" };
                }
                else {
                    // Possible error responses: 400, 404, 422, 429
                    operationFailed(`Failed to register user: ${request.status} ${request.statusText}`, "_registerWithUsernameAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to register user: ${error.message}`, "_registerWithUsernameAsync"));
            }
        });
    }
    /**
     * Logs the user in with the provided username and password. This is a single operation call, meaning if this
     * is successful the user is logged in at the end of it.
     * @param payload The payload to use for logging in.
     * @returns The response from the login attempt. A successful call will return a success status.
     * @see https://developers.xsolla.com/api/login/operation/auth-by-username-and-password/
     * @see https://developers.xsolla.com/api/login/operation/oauth-20-auth-by-username-and-password/
     * @private
     */
    _loginWithUsernameAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/login`);
            requestURL.search = params.toString();
            const requestBody = {
                username: payload.email,
                password: payload.password,
                // Default to false if not provided because the user needs to opt in to this.
                remember_me: (_a = payload.rememberMe) !== null && _a !== void 0 ? _a : false,
            };
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    // This is the expected response, as it means the user has successfully logged in.
                    const data = yield request.json();
                    const token = this._parseXsollaToken(data.login_url);
                    Wortal._internalAuth.isLoggedIn = true;
                    return {
                        status: "success",
                        token: token || "",
                    };
                }
                else {
                    // Possible error responses: 400, 401, 404, 422, 429
                    operationFailed(`Failed to log in user: ${request.status} ${request.statusText}`, "_loginWithUsernameAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to log in user: ${error.message}`, "_loginWithUsernameAsync"));
            }
        });
    }
    /**
     * Logs the user in with the provided email. This is a two-step operation, where the first step sends an email
     * to the user with a confirmation code to complete the login process. The second step completes the login process.
     *
     * If this is successful, the result will be a pending status, and the game should prompt the user to check their email
     * and allow them to enter a confirmation code to complete the login. That step is handled by _completeLoginWithEmailAsync.
     * @param payload The payload to use for logging in.
     * @returns The response from the login attempt. A successful call will return a pending status.
     * @see https://developers.xsolla.com/api/login/operation/jwt-start-auth-by-email/
     * @see https://developers.xsolla.com/api/login/operation/oauth-20-start-auth-by-email/
     * @private
     */
    _startLoginWithEmailAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/login/email/request`);
            requestURL.search = params.toString();
            const requestBody = {
                email: payload.email,
            };
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    const data = yield request.json();
                    const operationID = data.operation_id;
                    if (!operationID) {
                        operationFailed("Failed to start login with email: No operation ID returned.", "_startLoginWithEmailAsync");
                        return { status: "error" };
                    }
                    this._operationID = operationID;
                    return { status: "pending" };
                }
                else {
                    // Possible error responses: 400, 404, 422, 429
                    operationFailed(`Failed to log in user: ${request.status} ${request.statusText}`, "_startLoginWithEmailAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to log in user: ${error.message}`, "_startLoginWithEmailAsync"));
            }
        });
    }
    /**
     * Logs the user in with the provided phone number. This is a two-step operation, where the first step sends an email
     * to the user with a confirmation code to complete the login process. The second step completes the login process.
     *
     * If this is successful, the result will be a pending status, and the game should prompt the user to check their email
     * and allow them to enter a confirmation code to complete the login. That step is handled by _completeLoginWithPhonelAsync.
     * @param payload The payload to use for logging in.
     * @returns The response from the login attempt. A successful call will return a pending status.
     * @private
     */
    _startLoginWithPhoneAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/login/phone/request`);
            requestURL.search = params.toString();
            const requestBody = {
                phone_number: payload.phone,
            };
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    const data = yield request.json();
                    const operationID = data.operation_id;
                    if (!operationID) {
                        operationFailed("Failed to start login with phone: No operation ID returned.", "_startLoginWithPhoneAsync");
                        return { status: "error" };
                    }
                    this._operationID = operationID;
                    return { status: "pending" };
                }
                else {
                    // Possible error responses: 400, 404, 422, 429
                    operationFailed(`Failed to log in user: ${request.status} ${request.statusText}`, "_startLoginWithPhoneAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to log in user: ${error.message}`, "_startLoginWithPhoneAsync"));
            }
        });
    }
    /**
     * Completes the login process with the provided email and confirmation code. This is the second step in the
     * login process started by _startLoginWithEmailAsync. If this is successful, the user is logged in.
     * @param payload The payload to use for confirming the authentication.
     * @returns The response from the confirmation attempt.
     * @see https://developers.xsolla.com/api/login/operation/jwt-complete-auth-by-email/
     * @see https://developers.xsolla.com/api/login/operation/oauth-20-complete-auth-by-email/
     * @private
     */
    _completeLoginWithEmailAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/login/email/confirm`);
            requestURL.search = params.toString();
            const requestBody = {
                code: payload.code,
                email: payload.email,
                operation_id: this._lastOperationID,
            };
            if (!isValidString(this._lastOperationID)) {
                operationFailed("Failed to complete login with email: No operation ID found. This likely means the confirmation code has expired. Code lifetime is 3 minutes.", "_completeLoginWithEmailAsync");
                return { status: "error" };
            }
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    // This is the expected response, as it means the user has successfully logged in.
                    const data = yield request.json();
                    const token = this._parseXsollaToken(data.login_url);
                    Wortal._internalAuth.isLoggedIn = true;
                    return {
                        status: "success",
                        token: token || "",
                    };
                }
                else {
                    // Possible error responses: 401, 404, 422, 429
                    operationFailed(`Failed to log in user: ${request.status} ${request.statusText}`, "_completeLoginWithEmailAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to log in user: ${error.message}`, "_completeLoginWithEmailAsync"));
            }
        });
    }
    /**
     * Completes the login process with the provided phone number and confirmation code. This is the second step in the
     * login process started by _startLoginWithPhoneAsync. If this is successful, the user is logged in.
     * @param payload The payload to use for confirming the authentication.
     * @returns The response from the confirmation attempt.
     * @see https://developers.xsolla.com/api/login/operation/jwt-complete-auth-by-phone-number/
     * @see https://developers.xsolla.com/api/login/operation/oauth-20-complete-auth-by-phone-number/
     * @private
     */
    _completeLoginWithPhoneAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseURL = this._getBaseURL(payload);
            const queryParams = {
                projectId: this._xsollaConfig.loginProjectID,
            };
            const params = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                params.append(key, value.toString());
            });
            const requestURL = new URL(`${baseURL}/login/phone/confirm`);
            requestURL.search = params.toString();
            const requestBody = {
                code: payload.code,
                phone_number: payload.phone,
                operation_id: this._lastOperationID,
            };
            if (!isValidString(this._lastOperationID)) {
                operationFailed("Failed to complete login with email: No operation ID found. This likely means the confirmation code has expired. Code lifetime is 3 minutes.", "_completeLoginWithPhoneAsync");
                return { status: "error" };
            }
            try {
                const request = yield fetch(requestURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody),
                });
                if (request.status === 200) {
                    // This is the expected response, as it means the user has successfully logged in.
                    const data = yield request.json();
                    const token = this._parseXsollaToken(data.login_url);
                    Wortal._internalAuth.isLoggedIn = true;
                    return {
                        status: "success",
                        token: token || "",
                    };
                }
                else {
                    // Possible error responses: 401, 404, 422, 429
                    operationFailed(`Failed to log in user: ${request.status} ${request.statusText}`, "_completeLoginWithPhoneAsync");
                    return { status: "error" };
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to log in user: ${error.message}`, "_completeLoginWithPhoneAsync"));
            }
        });
    }
    //#endregion
    //#region Private Functions
    _getBaseURL(payload) {
        var _a;
        const protocol = (_a = payload.protocol) !== null && _a !== void 0 ? _a : "jwt";
        return protocol === "oauth2" ? API_ENDPOINTS.XSOLLA_LOGIN_OAUTH2 : API_ENDPOINTS.XSOLLA_LOGIN_JWT;
    }
    _getXsollaConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!Wortal._internalIsAPIInitialized) {
                yield delayUntilConditionMet(() => Wortal._internalIsAPIInitialized, "Auth: Waiting for APIs to initialize..");
            }
            const gameID = Wortal.session._internalSession.gameID;
            try {
                const response = yield fetch(`${API_ENDPOINTS.WORTAL_XSOLLA_CONFIG}?game=${gameID}&platform=${Wortal._internalPlatform}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": 'application/json'
                    },
                });
                if (response.status !== 200) {
                    return Promise.reject(operationFailed(`Failed to fetch Xsolla config: ${response.status} ${response.statusText}`, "_getXsollaConfig"));
                }
                const payload = yield response.json();
                this._xsollaConfig.wortalGameID = payload.wortalGameID;
                this._xsollaConfig.projectID = payload.xsollaProjectID || 0;
                this._xsollaConfig.loginProjectID = payload.xsollaLoginProjectID || "";
                Wortal._log.debug("Fetched Xsolla config.");
            }
            catch (error) {
                return Promise.reject(operationFailed(`Failed to fetch Xsolla config: ${error.message}`, "_getXsollaConfig"));
            }
        });
    }
    _parseXsollaToken(callbackURL) {
        const url = new URL(callbackURL);
        const token = url.searchParams.get("token");
        if (!token) {
            Wortal._log.warn("No Xsolla token found in callback URL.");
            return "";
        }
        this._xsollaConfig.jwtToken = token;
        return token;
    }
}
