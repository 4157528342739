var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { rethrowError_Yandex } from "../../errors/error-handler";
import Wortal from "../../index";
import { delayUntilConditionMet } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a Yandex player.
 * @hidden
 */
export class YandexPlayer extends Player {
    constructor() {
        super();
    }
    /**
     * Gets the Yandex player object.
     * @hidden
     */
    get playerObject() {
        return this._playerObject;
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this._setPlayerObject();
                this._data = {
                    id: this._playerObject.getUniqueID(),
                    name: this._playerObject.getName(),
                    photo: this._playerObject.getPhoto("medium"), //TODO: check if this is the right size
                    isFirstPlay: false,
                    daysSinceFirstPlay: 0,
                };
            }
            catch (error) {
                throw rethrowError_Yandex(error, "initializeImpl");
            }
        });
    }
    _setPlayerObject() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this._playerObject !== "undefined") {
                return;
            }
            // This might be called before the platform SDK is available.
            if (typeof Wortal._internalPlatformSDK === "undefined") {
                yield delayUntilConditionMet(() => typeof Wortal._internalPlatformSDK !== "undefined", "Waiting for Wortal._internalPlatformSDK to be defined.");
            }
            try {
                this._playerObject = yield Wortal._internalPlatformSDK.getPlayer();
            }
            catch (error) {
                return Promise.reject(rethrowError_Yandex(error, "_setPlayerObject"));
            }
        });
    }
}
