import { WORTAL_SDK_EVENTS } from "../data/core-data";
import { wortalEvent } from "./wortal-utils";
/**
 * Logger class for the SDK. This is used to log messages to the console. Use this over console logging directly.
 * @module Logger
 */
export class WortalLogger {
    constructor() {
        this._logPrefix = " Wortal ";
        this._callLogPrefix = " API ";
        this._internalCallLogPrefix = " INTERNAL ";
        this._performanceLogPrefix = " PERFORMANCE ";
        this._statusLogPrefix = " STATUS ";
        this._logTextStyle = `font-weight: normal;`;
        this._logPrefixStyle = `
    background: #8258A4;
    border-radius: 5px;
    color: white;
    font-weight: bold;`;
        this._logCallTagStyle = `
    background: #A45882;
    border-radius: 5px;
    color: white;
    font-weight: bold;`;
        this._logInternalTagStyle = `
    background: #5F5666;
    border-radius: 5px;
    color: white;
    font-weight: bold;`;
        this._logPerformanceTagStyle = `
    background: #75AA00;
    border-radius: 5px;
    color: white;
    font-weight: bold;`;
        this._logStatusTagStyle = `
    background: #007FFF;
    border-radius: 5px;
    color: white;
    font-weight: bold;`;
    }
    /**
     * Logs a debug message to the console. Logs at the verbose level.
     * @hidden
     */
    debug(message, args) {
        console.debug(`%c${this._logPrefix}%c ${message}`, this._logPrefixStyle, this._logTextStyle, args);
    }
    /**
     * Logs an info message to the console. Logs at the info level.
     * @hidden
     */
    info(message, args) {
        console.info(`%c${this._logPrefix}%c ${message}`, this._logPrefixStyle, this._logTextStyle, args);
    }
    /**
     * Logs a warning message to the console. Logs at the warning level.
     * @hidden
     */
    warn(message, args) {
        console.warn(`%c${this._logPrefix}%c ${message}`, this._logPrefixStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_WARNING, { message, args });
    }
    /**
     * Logs an error message to the console. Logs at the error level. Does not throw an exception, just logs the message.
     * @hidden
     */
    exception(message, args) {
        console.error(`%c${this._logPrefix}%c ${message}`, this._logPrefixStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_ERROR, { message, args });
    }
    /**
     * Logs a status message to the console. This is used to track SDK status. Logs at the verbose level.
     * @hidden
     */
    status(message, args) {
        console.debug(`%c${this._logPrefix}%c${this._statusLogPrefix}%c ${message}`, this._logPrefixStyle, this._logStatusTagStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_STATUS, { message, args });
    }
    /**
     * Logs an SDK function call to the console. This is used to track API usage. Logs at the verbose level.
     * @hidden
     */
    apiCall(fn, args) {
        console.debug(`%c${this._logPrefix}%c${this._callLogPrefix}%c ${fn}`, this._logPrefixStyle, this._logCallTagStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_API_CALL, { fn, args });
    }
    /**
     * Logs an SDK function success. This is used to track API usage in the QA tool and report a
     * successful API call. Does not actually log to console, only fires an event.
     * @hidden
     */
    apiSuccess(fn, args) {
        wortalEvent(WORTAL_SDK_EVENTS.SDK_API_SUCCESS, { fn, args });
    }
    /**
     * Logs an SDK function error. This is used to track API usage in the QA tool and report a
     * failed API call. Does not actually log to console, only fires an event.
     * @hidden
     */
    apiError(fn, args) {
        wortalEvent(WORTAL_SDK_EVENTS.SDK_API_ERROR, { fn, args });
    }
    /**
     * Logs an internal SDK function call to the console. This is used to track internal SDK usage. Logs at the verbose level.
     * @hidden
     */
    internalCall(fn, args) {
        console.debug(`%c${this._logPrefix}%c${this._internalCallLogPrefix}%c ${fn}`, this._logPrefixStyle, this._logInternalTagStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_INTERNAL_CALL, { fn, args });
    }
    /**
     * Logs a performance message to the console. This is used to track performance of the SDK. Logs at the verbose level.
     * @hidden
     */
    performanceLog(message, args) {
        console.debug(`%c${this._logPrefix}%c${this._performanceLogPrefix}%c ${message}`, this._logPrefixStyle, this._logPerformanceTagStyle, this._logTextStyle, args);
        wortalEvent(WORTAL_SDK_EVENTS.SDK_PERFORMANCE, { message, args });
    }
}
