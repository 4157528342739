var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Wortal from "../../index";
import { generateUUID } from "../../utils/wortal-utils";
/**
 * Represents a player in the game. To access info about the current player, use the Wortal.player API.
 * This is used to access info about other players such as friends or leaderboard entries.
 */
export class Player {
    constructor() {
        this._data = {
            id: "",
            name: "",
            photo: "",
            isFirstPlay: false,
            daysSinceFirstPlay: 0,
        };
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            this._data.id = generateUUID();
            this._data.name = "Player";
            this._data.photo = "https://storage.googleapis.com/html5gameportal.com/images/avatar.jpg";
            this._data.isFirstPlay = false;
            Wortal._log.debug("Player initialized: ", this._data);
            return Promise.resolve();
        });
    }
    /**
     * ID of the player. This is platform-dependent.
     */
    get id() {
        return this._data.id;
    }
    /**
     * Name of the player.
     */
    get name() {
        return this._data.name;
    }
    /**
     * URL for the player's photo.
     */
    get photo() {
        return this._data.photo;
    }
    /**
     * Is this the first time the player has played this game or not.
     */
    get isFirstPlay() {
        return this._data.isFirstPlay;
    }
    /**
     * Days since the first time the player has played this game.
     */
    get daysSinceFirstPlay() {
        return this._data.daysSinceFirstPlay;
    }
    /**
     * Facebook app-scoped ID of the player. This is only available if the player is playing on Facebook.
     * @hidden
     */
    get asid() {
        return this._data.asid;
    }
}
