var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SDK_SRC } from "../../data/core-data";
import Wortal from "../../index";
import { AdConfig } from "./ad-config";
/**
 * Debug implementation of AdConfig. This is used for local testing or test builds. Relies on AFG only to display
 * placeholder ads.
 * @hidden
 */
export class AdConfigDebug extends AdConfig {
    constructor() {
        super(...arguments);
        this._afgConfig = {
            channelHostID: "",
            channelID: "",
            clientID: "ca-pub-123456789",
            hostID: "",
            frequencyCap: "1s",
            pageURL: window.location.href,
            isDebugMode: true,
            isEnabled: true,
            source: ""
        };
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.debug("Ads: Initializing ad config..");
            try {
                yield this._initializeAFG();
            }
            catch (error) {
                return Promise.reject(error);
            }
            Wortal._log.debug("Ads: Ad config initialized.");
        });
    }
    _internalAFGConfig() {
        return this._afgConfig;
    }
    _initializeAFG() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.internalCall("_initializeAFG");
            return new Promise((resolve) => {
                // Create the AFG script.
                const script = document.createElement("script");
                script.setAttribute("src", SDK_SRC.AFG);
                script.setAttribute("type", "text/javascript");
                // Set attributes based on AFG config.
                script.setAttribute("data-ad-client", "ca-pub-123456789");
                script.setAttribute("data-adbreak-test", "on");
                script.onload = () => {
                    // This is used by GPT to build ad requests.
                    window.googletag = window.googletag || {};
                    window.googletag.cmd = window.googletag.cmd || [];
                    // This is the global function that the AdSense SDK uses to register ad breaks.
                    // https://developers.google.com/ad-placement/docs/html5-game-structure
                    window.adsbygoogle = window.adsbygoogle || [];
                    window.adBreak = window.adConfig = function (o) {
                        window.adsbygoogle.push(o);
                    };
                    window.adConfig({ preloadAdBreaks: "on" });
                    this._afgConfig.isEnabled = true;
                    Wortal._log.debug("Ads: AFG SDK loaded.");
                    resolve();
                };
                script.onerror = () => {
                    this._afgConfig.isEnabled = false;
                    Wortal.ads._internalAdConfig.setAdBlocked(true);
                    Wortal._log.warn("Ad blocker detected. AFG SDK failed to load.");
                    resolve();
                };
                document.head.appendChild(script);
            });
        });
    }
}
