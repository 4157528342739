/**
 * Contains data about the state of the game such as timers and analytics data.
 * @hidden
 */
export class GameState {
    constructor() {
        this._data = {
            levelName: "",
            levelTimer: 0,
            levelTimerHandle: 0,
            gameLoadTimer: 0,
        };
    }
    get gameLoadTimer() {
        return this._data.gameLoadTimer;
    }
    startGameLoadTimer() {
        this._data.gameLoadTimer = performance.now();
    }
    stopGameLoadTimer() {
        this._data.gameLoadTimer = performance.now() - this._data.gameLoadTimer;
    }
    get levelName() {
        return this._data.levelName;
    }
    setLevelName(name) {
        this._data.levelName = name;
    }
    get levelTimer() {
        return this._data.levelTimer;
    }
    resetLevelTimer() {
        this._data.levelTimer = 0;
    }
    startLevelTimer() {
        this._data.levelTimerHandle = window.setInterval(() => this._data.levelTimer += 1, 1000);
    }
    clearLevelTimerHandle() {
        if (this._data.levelTimerHandle !== null) {
            clearInterval(this._data.levelTimerHandle);
        }
        this._data.levelTimerHandle = 0;
    }
}
