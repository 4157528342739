import Wortal from "../index";
import { invalidParams } from "../errors/error-handler";
import { isValidShareDestination, isValidString } from "./validators";
//#region SDK Utility functions
/**
 * Functions stored here will be called when the game is paused.
 * @hidden
 */
export const onPauseFunctions = [];
/**
 * Functions stored here will be called when the game is resumed.
 * @hidden
 */
export const onResumeFunctions = [];
/**
 * Functions stored here will be called when the player logs in.
 * @hidden
 */
export const onLoginFunctions = [];
/**
 * Functions stored here will be called when the audio status changes.
 * @hidden
 */
export const onAudioStatusChangeFunctions = [];
/**
 * Logs an event to the Wortal platform. This is used to track events such as game start, game over, and other
 * important events.
 * @param eventName Name of the event to log.
 * @param data Optional data to log with the event. Must be serializable.
 * @hidden
 */
export function wortalEvent(eventName, data) {
    const event = {
        type: "wortal-sdk-event",
        name: eventName,
        payload: data,
    };
    // We only send these events in debug mode for the QA tool to track.
    if (window.location.host.includes("wortal") || window.location.host.includes("localhost")) {
        // Just in case we send something that can't be serialized. Failing here shouldn't affect the game.
        try {
            parent.postMessage(event, "*");
        }
        catch (error) {
            // Don't use Wortal._log here as it will try to send the event again, causing an infinite loop.
            console.warn("WortalEvent: Failed to send event.", error);
        }
    }
}
/**
 * Does what the name suggests -- delays execution until a condition is met.
 * @param {Function} condition Function that returns a boolean. If the boolean is true, the promise will resolve.
 * @param {string} message Message to log while waiting for the condition to be met.
 * @hidden
 */
export function delayUntilConditionMet(condition, message = "") {
    return new Promise(resolve => {
        const checkIfConditionMet = () => {
            if (condition()) {
                resolve();
            }
            else {
                if (isValidString(message)) {
                    Wortal._log.debug(message);
                }
                setTimeout(checkIfConditionMet, 100);
            }
        };
        checkIfConditionMet();
    });
}
/**
 * Gets a parameter from the URL.
 * Borrowed from https://stackoverflow.com/a/901144.
 * @param name Name of the parameter to get from the URL.
 * @returns {string|null} The value of the parameter, or null if it does not exist.
 * @hidden
 */
export function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(window.location.href);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
/**
 * Gets all parameters from the URL.
 * @returns {Record<string, string>} Object containing all parameters from the URL.
 * @hidden
 */
export function getAllQueryParameters() {
    const params = {};
    const queryString = window.location.search.slice(1);
    const pairs = queryString.split("&");
    for (const pair of pairs) {
        const [key, value] = pair.split("=");
        params[key] = value;
    }
    return params;
}
/**
 * Generates a UUID. This can be used for a player or session ID.
 * @returns {string} Unique ID.
 * @hidden
 */
export function generateUUID() {
    let uuid = "";
    // We should always be using https, but for local testing (specifically for AddictingGames) we can't guarantee it.
    try {
        uuid = crypto.randomUUID();
    }
    catch (error) {
        Wortal._log.exception(`Failed to generate UUID because ${error}`);
    }
    return isValidString(uuid) ? uuid : "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c => (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16));
}
/**
 * Checks the current state of the document and adds a loading cover if the document is finished loading. Otherwise,
 * adds a listener to add the loading cover when the document is finished loading. This is used for the Wortal and GD
 * platforms to prevent the game canvas from being shown before the preroll ad finishes.
 * @hidden
 */
export function addLoadingListener() {
    const platform = Wortal.session.getPlatform();
    if (document.readyState === "loading") {
        if (platform === "wortal" || platform === "gd") {
            document.addEventListener("DOMContentLoaded", addLoadingCover);
        }
    }
    else {
        if (platform === "wortal" || platform === "gd") {
            addLoadingCover();
        }
    }
}
/**
 * Adds a loading cover to the document. This is just a blank div that covers the entire screen. It is used to prevent
 * the game canvas from being shown before the preroll ad finishes.
 * @hidden
 */
export function addLoadingCover() {
    if (Wortal.ads._internalAdConfig.hasPrerollShown || Wortal.ads._internalAdConfig.isAdBlocked) {
        return;
    }
    const cover = document.createElement("div");
    cover.id = "loading-cover";
    cover.style.cssText = "background: #000000; width: 100%; height: 100%; position: fixed; z-index: 100;";
    document.body.prepend(cover);
}
/**
 * Removes the loading cover from the document. This is used to remove the loading cover after the preroll ad finishes.
 * Failure to call this when the loading cover has been added will result in the game being hidden and unplayable.
 * @hidden
 */
export function removeLoadingCover() {
    if (document.getElementById("loading-cover")) {
        document.getElementById("loading-cover").style.display = "none";
    }
}
/**
 * Adds a listener for the visibilitychange event. This is used to pause and resume the game when the tab is hidden.
 * @hidden
 */
export function addPauseResumeListener() {
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            onPauseFunctions.forEach((callback) => {
                callback();
            });
            Wortal.session._internalSession.isAudioEnabled = false;
        }
        if (document.visibilityState === "visible") {
            onResumeFunctions.forEach((callback) => {
                callback();
            });
            Wortal.session._internalSession.isAudioEnabled = true;
        }
    });
}
/**
 * Adds an event handler to the ExternalEvents object. This is used to trigger callbacks from external SDKs such as
 * GD and GameMonetize that rely on a window object with a global event handler.
 * @param {string} eventName Name of the event.
 * @param {Function} callback Callback function to be called when the event is triggered.
 * @hidden
 */
export function addExternalCallback(eventName, callback) {
    if (typeof callback !== "function") {
        throw invalidParams(undefined, "addExternalCallback()");
    }
    if (typeof Wortal.session._internalSession.externalCallbacks !== "undefined") {
        Wortal.session._internalSession.externalCallbacks[eventName] = callback;
    }
    else {
        Wortal._log.exception("externalCallbacks is undefined. This is a fatal error that should have been caught during initialization.");
    }
}
/**
 * Triggers a callback from an external SDK. This is used by SDKs such as GD and GameMonetize that use a window
 * object with a global event handler to trigger callbacks.
 * @param {string} value Name of the event to trigger.
 * @hidden
 */
export function externalSDKEventTrigger(value) {
    if (typeof Wortal.session._internalSession.externalCallbacks !== "undefined") {
        const callback = Wortal.session._internalSession.externalCallbacks[value];
        if (typeof callback !== "undefined") {
            Wortal._log.debug(`External event triggered. Event: ${value}`);
            callback();
        }
        else {
            Wortal._log.debug(`External event triggered, but no callback is defined for this event. Event: ${value}`);
        }
    }
    else {
        Wortal._log.exception("External event triggered, but externalCallbacks is undefined. This is a fatal error that should have been caught during initialization.");
    }
}
/**
 * Awaits a callback from the Telegram SDK. Removes the listener after the callback is triggered or after a timeout.
 * @param eventName Name of the event to wait for.
 * @returns {Promise<any>} Promise that resolves when the event is triggered. Contains the data from the event, if any exists.
 * @hidden
 */
export function waitForTelegramCallback(eventName, timeout = 5000) {
    return new Promise((resolve, reject) => {
        let timeoutID = null;
        const eventHandler = ({ data }) => {
            const telegramData = (data === null || data === void 0 ? void 0 : data.playdeck) || (data === null || data === void 0 ? void 0 : data.wortal);
            if ((telegramData === null || telegramData === void 0 ? void 0 : telegramData.method) === eventName) {
                Wortal._log.debug(`Telegram event callback. Event: ${eventName}`, telegramData === null || telegramData === void 0 ? void 0 : telegramData.value);
                window.removeEventListener("message", eventHandler);
                clearTimeout(timeoutID);
                resolve(telegramData === null || telegramData === void 0 ? void 0 : telegramData.value);
            }
        };
        window.addEventListener("message", eventHandler);
        timeoutID = setTimeout(() => {
            window.removeEventListener("message", eventHandler);
            reject(new Error(`Timeout: '${eventName}' event not triggered within the timeout range.`));
        }, timeout);
    });
}
/**
 * Detects the device the player is using. This is based on navigator.userAgent and is not guaranteed to be accurate.
 * @hidden
 */
export function detectDevice() {
    //TODO: replace this with Navigator.userAgentData when its widely supported
    if (/android/i.test(navigator.userAgent)) {
        return "ANDROID";
    }
    else if (/iphone/i.test(navigator.userAgent)) {
        return "IOS";
    }
    else if (/ipad/i.test(navigator.userAgent)) {
        return "IOS";
    }
    else {
        return "DESKTOP";
    }
}
/**
 * Clamps a number between a min and max value.
 * @param num Number to clamp.
 * @param min Minimum value.
 * @param max Maximum value.
 */
export function clampNumber(num, min, max) {
    return Math.min(Math.max(num, min), max);
}
//#endregion
//#region Wortal page functions
window.shareGame = function (destination, message) {
    if (!isValidShareDestination(destination)) {
        throw invalidParams(undefined, "shareGame()");
    }
    switch (destination) {
        case "facebook":
            return _shareOnFacebook(message);
        case "twitter":
            return _shareOnTwitter(message);
    }
};
/**
 * @hidden
 * @private
 */
function _getShareUrl() {
    return getParameterByName("shareUrl");
}
/**
 * @hidden
 * @private
 */
function _shareOnFacebook(message) {
    const shareUrl = _getShareUrl();
    const url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${message}`;
    window.open(url, "_blank");
}
/**
 * @hidden
 * @private
 */
function _shareOnTwitter(message) {
    const shareUrl = _getShareUrl();
    const url = "https://twitter.com/intent/tweet";
    window.open(`${url}?url=${shareUrl}&text=${message}`, "_blank");
}
//#endregion
