var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ENDPOINTS } from "../../data/core-data";
import { initializationError, operationFailed } from "../../errors/error-handler";
import Wortal from "../../index";
import { isValidString } from "../../utils/validators";
import { AdConfig } from "./ad-config";
/**
 * Facebook implementation of AdConfig.
 * @hidden
 */
export class AdConfigFacebook extends AdConfig {
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.debug("Initializing AdConfig..");
            yield this._getAdUnitIDs();
            Wortal._log.debug("AdConfig initialized.", this._data);
        });
    }
    /**
     * Fetches the ad unit IDs from Wortal API.
     * @example JSON returned
     *{
     *  "gameID": 68,
     *  "ads": [
     *     {
     *      "display_format": "interstitial",
     *      "placement_id": "1284783688986969_1317853085680029"
     *     }
     *   ]
     *}
     */
    _getAdUnitIDs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                Wortal._log.debug("Ads: Fetching Facebook ad units from Wortal API..");
                if (typeof window.wortalGameID === "undefined") {
                    return Promise.reject(initializationError("Failed to retrieve wortalGameID. This may be due to an error when uploading the game bundle to Facebook.", "_getAdUnitIDs"));
                }
                const url = API_ENDPOINTS.WORTAL_GAME + window.wortalGameID + "/facebook/ad-placements/";
                const response = yield fetch(url);
                Wortal._log.debug("Ads: Fetching Facebook ad units from Wortal API response: ", response);
                const adUnits = yield response.json();
                Wortal._log.debug("Ads: Fetching Facebook ad units from Wortal API response JSON: ", adUnits);
                if (!adUnits || !adUnits.ads) {
                    Wortal._log.exception("Ads: Failed to retrieve ad units. This may be due to a server issue or the API being currently unavailable.");
                    return;
                }
                if (adUnits.ads.length === 0) {
                    Wortal._log.warn("Ads: No ad units returned. Please contact your Wortal representative to set up your ad units before attempting to show ads.");
                    return;
                }
                for (let i = 0; i < adUnits.ads.length; i++) {
                    if (adUnits.ads[i].display_format === "interstitial" && !isValidString(this._data.interstitialId)) {
                        this._data.interstitialId = adUnits.ads[i].placement_id;
                    }
                    else if (adUnits.ads[i].display_format === "rewarded_video" && !isValidString(this._data.rewardedId)) {
                        this._data.rewardedId = adUnits.ads[i].placement_id;
                    }
                    else if (adUnits.ads[i].display_format === "banner" && !isValidString(this._data.bannerId)) {
                        this._data.bannerId = adUnits.ads[i].placement_id;
                    }
                }
            }
            catch (error) {
                return Promise.reject(operationFailed(error, "_getAdUnitIDs"));
            }
        });
    }
}
