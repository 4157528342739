var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Wortal from "../../index";
import { generateUUID } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a KantanGame player, they don't have player id so we create it ourself.
 * @hidden
 */
export class KantanGamePlayer extends Player {
    constructor(player) {
        super();
        this._data = {
            id: "",
            name: "",
            photo: "",
            isFirstPlay: false,
            daysSinceFirstPlay: 0,
        };
        let id = localStorage.getItem("kantangame_generated_player_id");
        if (!id) {
            id = generateUUID();
            localStorage.setItem("kantangame_generated_player_id", id);
        }
        this._data.id = (player === null || player === void 0 ? void 0 : player.id) || id;
        this._data.name = (player === null || player === void 0 ? void 0 : player.name) || "Player";
        this._data.photo = (player === null || player === void 0 ? void 0 : player.photo) || "https://storage.googleapis.com/html5gameportal.com/images/avatar.jpg";
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.debug("Player initialized: ", this._data);
            this._data.isFirstPlay = this._isWortalFirstPlay();
            Wortal._log.debug("Player initialized: ", this._data);
            return Promise.resolve(undefined);
        });
    }
    _isWortalFirstPlay() {
        const cookieDate = this._getCookie(Wortal.session._internalSession.gameID);
        if (cookieDate !== "") {
            this._data.daysSinceFirstPlay = this._getTimeFromCookieCreation(cookieDate);
            return false;
        }
        else {
            this._setCookie(Wortal.session._internalSession.gameID);
            return true;
        }
    }
    _getCookie(gameId) {
        var _a;
        const value = "; " + document.cookie;
        const parts = value.split("; kantangame-" + gameId + "=");
        return ((parts.length === 2 && ((_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(";").shift())) || "");
    }
    _setCookie(gameId) {
        const key = "kantangame-" + gameId;
        const value = new Date().toISOString().slice(0, 10);
        const date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = key + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    }
    // We store the creation date in the wortal-gameID cookie in this format: yyyy/mm/dd.
    // We'll parse that here and then calculate approximately how many days have elapsed
    // since then. We use that to track retention.
    _getTimeFromCookieCreation(date) {
        const year = +date.substring(0, 4);
        const month = +date.substring(5, 7) - 1; // Month range is 0 - 11.
        const day = +date.substring(8, 10);
        const lastPlay = Date.UTC(year, month, day);
        const timeDelta = Date.now() - lastPlay;
        return Math.round(timeDelta / 1000 / 60 / 60 / 24);
    }
}
