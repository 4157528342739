var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_URL, WORTAL_API } from "../data/core-data";
import { invalidParams } from "../errors/error-handler";
import { isValidAuthMethod, isValidEmail, isValidPassword, isValidPhoneNumber, isValidString } from "../utils/validators";
import { onLoginFunctions } from "../utils/wortal-utils";
/**
 * The Auth API is an internal interface that provides the necessary methods for user authentication. These should
 * all be accessed via Wortal.authenticateAsync from the developer's side.
 * @internal
 */
export class AuthAPI {
    constructor() {
        //#region Properties
        this._isLoggedIn = false;
        //#endregion
    }
    //#endregion
    //#region Public API
    authenticateAsync(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = payload.requestType;
            switch (request) {
                case "login":
                    return this._loginAsync(payload);
                case "confirmation":
                    return this._confirmAsync(payload);
                case "logout":
                    return this._logoutAsync();
                case "register":
                    return this._registerAsync(payload);
                case "reset":
                    return this._resetPasswordAsync(payload);
                default:
                    return Promise.reject(invalidParams(`${request} is not a valid request type.`, "authenticateAsync", API_URL.AUTHENTICATE_ASYNC));
            }
        });
    }
    //#endregion
    //#region Internal API
    get isLoggedIn() {
        return this._isLoggedIn;
    }
    set isLoggedIn(value) {
        this._isLoggedIn = value;
        if (value) {
            onLoginFunctions.forEach((func) => func());
        }
    }
    /**
     * Logs the user in with the provided payload.
     * @param payload The payload to use for logging in.
     * @returns The response from the login attempt.
     */
    _loginAsync(payload) {
        const validation = this._validateLoginAsync(payload);
        if (!validation.valid) {
            return Promise.reject(validation.error);
        }
        return this._loginAsyncImpl(payload);
    }
    /**
     * Confirms the user's authentication with the provided payload.
     * @param payload The payload to use for confirming the authentication.
     * @returns The response from the confirmation attempt.
     */
    _confirmAsync(payload) {
        const validation = this._validateConfirmAsync(payload);
        if (!validation.valid) {
            return Promise.reject(validation.error);
        }
        return this._confirmAsyncImpl(payload);
    }
    /**
     * Logs the user out.
     * @returns The response from the logout attempt.
     */
    _logoutAsync() {
        return this._logoutAsyncImpl();
    }
    /**
     * Registers a new user with the provided payload.
     * @param payload The payload to use for registration.
     * @returns The response from the registration attempt.
     */
    _registerAsync(payload) {
        const validation = this._validateRegisterAsync(payload);
        if (!validation.valid) {
            return Promise.reject(validation.error);
        }
        return this._registerAsyncImpl(payload);
    }
    /**
     * Resets the user's password with the provided payload.
     * @param payload The payload to use for resetting the password.
     * @returns The response from the password reset attempt.
     */
    _resetPasswordAsync(payload) {
        return this._resetPasswordAsyncImpl(payload);
    }
    //#endregion
    //#region Validation
    _validateLoginAsync(payload) {
        if (typeof payload.method === "undefined" || !isValidAuthMethod(payload.method)) {
            return {
                valid: false,
                error: invalidParams("method is required and must be a valid AuthMethod.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "email" && (typeof payload.email === "undefined" || !isValidEmail(payload.email))) {
            return {
                valid: false,
                error: invalidParams("email is required and must be a valid email address.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "password" && (typeof payload.password === "undefined" || !isValidPassword(payload.password))) {
            return {
                valid: false,
                error: invalidParams("password is required and must not be empty.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "phone" && (typeof payload.phone === "undefined" || !isValidPhoneNumber(payload.phone))) {
            return {
                valid: false,
                error: invalidParams("phone is required and must be a valid phone number.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        return { valid: true };
    }
    _validateConfirmAsync(payload) {
        if (typeof payload.method === "undefined" || !isValidAuthMethod(payload.method)) {
            return {
                valid: false,
                error: invalidParams("method is required and must be a valid AuthMethod.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (typeof payload.code === "undefined" || !isValidString(payload.code)) {
            return {
                valid: false,
                error: invalidParams("code is required and must not be empty.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "email" && (typeof payload.email === "undefined" || !isValidEmail(payload.email))) {
            return {
                valid: false,
                error: invalidParams("email is required and must be a valid email address.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "phone" && (typeof payload.phone === "undefined" || !isValidPhoneNumber(payload.phone))) {
            return {
                valid: false,
                error: invalidParams("phone is required and must be a valid phone number.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        return { valid: true };
    }
    _validateRegisterAsync(payload) {
        if (typeof payload.method === "undefined" || !isValidAuthMethod(payload.method)) {
            return {
                valid: false,
                error: invalidParams("method is required and must be a valid AuthMethod.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        if (payload.method === "password" && (typeof payload.password === "undefined" || !isValidPassword(payload.password))) {
            return {
                valid: false,
                error: invalidParams("password is required and must not be empty.", WORTAL_API.AUTHENTICATE_ASYNC, API_URL.AUTHENTICATE_ASYNC)
            };
        }
        return { valid: true };
    }
}
