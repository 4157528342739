var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { rethrowError_CrazyGames } from "../../errors/error-handler";
import Wortal from "../../index";
import { delayUntilConditionMet, generateUUID } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a CrazyGames player.
 * @hidden
 */
export class CrazyGamesPlayer extends Player {
    constructor(player) {
        super();
        this._data.id = (player === null || player === void 0 ? void 0 : player.username) || generateUUID();
        this._data.name = (player === null || player === void 0 ? void 0 : player.username) || "Player";
        this._data.photo = (player === null || player === void 0 ? void 0 : player.profilePictureUrl) || "https://images.crazygames.com/userportal/avatars/4.png";
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            let player = null;
            try {
                player = yield this._initializeCrazyGamesPlayer();
            }
            catch (error) {
                Wortal._log.exception("Error initializing CrazyGames player: ", error);
            }
            this._data.id = (player === null || player === void 0 ? void 0 : player.username) || generateUUID();
            this._data.name = (player === null || player === void 0 ? void 0 : player.username) || "Player";
            this._data.photo = (player === null || player === void 0 ? void 0 : player.profilePictureUrl) || "https://images.crazygames.com/userportal/avatars/4.png";
            Wortal._log.debug("Player initialized: ", this._data);
        });
    }
    _initializeCrazyGamesPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            let isUserAPIAvailable = false;
            const defaultPlayer = {
                username: "Player",
                profilePictureUrl: "https://images.crazygames.com/userportal/avatars/4.png",
            };
            // The CrazyGames SDK takes a little longer to initialize than the others, so we have to wait for it here.
            if (typeof Wortal._internalPlatformSDK === "undefined") {
                yield delayUntilConditionMet(() => {
                    return typeof Wortal._internalPlatformSDK !== "undefined";
                }, "Waiting for CrazyGames SDK to load...");
            }
            try {
                isUserAPIAvailable = yield this._checkIsUserAPIAvailable();
            }
            catch (error) {
                Wortal._log.exception(`Error checking if user API is available: ${error.message}`);
                return defaultPlayer;
            }
            if (!isUserAPIAvailable) {
                return defaultPlayer;
            }
            try {
                return yield this._fetchCrazyGamesPlayer();
            }
            catch (error) {
                Wortal._log.exception(`Error fetching CrazyGames player: ${error.message}`);
                return defaultPlayer;
            }
        });
    }
    _checkIsUserAPIAvailable() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield Wortal._internalPlatformSDK.user.isUserAccountAvailable;
            }
            catch (error) {
                Wortal._log.exception(`Error checking if user API is available: ${error.message}`);
                return false;
            }
        });
    }
    _fetchCrazyGamesPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const player = yield Wortal._internalPlatformSDK.user.getUser();
                Wortal._log.debug("CrazyGames player fetched:", player);
                if (player !== null) {
                    Wortal._internalAuth.isLoggedIn = true;
                }
                return player;
            }
            catch (error) {
                return Promise.reject(rethrowError_CrazyGames(error, "getUser"));
            }
        });
    }
}
