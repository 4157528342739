var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Wortal from "../../index";
import { Player } from "./player";
/**
 * Represents a Facebook player.
 * @hidden
 */
export class FacebookPlayer extends Player {
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                Wortal._log.debug("Player: Fetching ASID...");
                const asid = yield Wortal.player.getASIDAsync();
                this._data.asid = asid;
                Wortal._log.debug("Player: ASID fetched: ", asid);
                this._data.id = Wortal._internalPlatformSDK.player.getID();
                this._data.name = Wortal._internalPlatformSDK.player.getName();
                this._data.photo = Wortal._internalPlatformSDK.player.getPhoto();
                this._data.isFirstPlay = false;
                Wortal._log.debug("Player initialized: ", this._data);
            }
            catch (error) {
                Wortal._log.exception("Player: Error fetching ASID: ", error);
                return Promise.reject(error);
            }
        });
    }
}
