/**
 * Validates if the given object is a valid string and not empty.
 * @hidden
 */
export function isValidString(obj) {
    return (typeof obj === "string" && obj !== "");
}
/**
 * Validates if the given object is a valid number and not NaN.
 * @hidden
 */
export function isValidNumber(obj) {
    if (typeof obj !== "number") {
        return false;
    }
    else {
        return !isNaN(obj);
    }
}
/**
 * Validates if the given object is a valid PlacementType.
 * @see PlacementType
 * @hidden
 */
export function isValidPlacementType(value) {
    return ["preroll", "start", "pause", "next", "browse", "reward"].includes(value);
}
/**
 * Validates if the given object contains a valid text property in a payload.
 * @hidden
 */
export function isValidPayloadText(obj) {
    if (typeof obj === "string" && obj !== "") {
        return true;
    }
    else if (typeof obj === "object") {
        if (typeof obj.default === "string" && obj.default !== "") {
            return true;
        }
    }
    return false;
}
/**
 * Validates if the given object contains a valid image property in a payload. This needs to be a base64 encoded string.
 * @hidden
 */
export function isValidPayloadImage(obj) {
    if (typeof obj === "string" && obj !== "") {
        if (obj.startsWith("data:")) {
            return true;
        }
    }
    return false;
}
/**
 * Validates if the given object contains a valid productID property in a purchase config.
 * @hidden
 */
export function isValidPurchaseConfig(obj) {
    return (typeof obj.productID === "string" && obj.productID !== "");
}
/**
 * Validates if the given object contains a valid shareTo property.
 * @hidden
 */
export function isValidShareDestination(value) {
    return ["facebook", "twitter"].includes(value);
}
/**
 * Validates if the given object contains a valid AuthMethod.
 * @hidden
 */
export function isValidAuthMethod(value) {
    return ["password", "email", "phone", "social"].includes(value);
}
/**
 * Validates if the given object contains a valid email. Length must be between 1 and 255 characters.
 * @hidden
 */
export function isValidEmail(value) {
    if (!isValidString(value)) {
        return false;
    }
    if (value.length > 255) {
        return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
}
/**
 * Validates if the given object contains a valid password. Length must be between 6 and 100 characters.
 * @hidden
 */
export function isValidPassword(value) {
    if (!isValidString(value)) {
        return false;
    }
    return !(value.length < 6 || value.length > 100);
}
/**
 * Validates if the given object contains a valid phone number. Must start with a '+' followed by digits.
 * Spaces, dashes, and parentheses are allowed.
 * @hidden
 */
export function isValidPhoneNumber(value) {
    if (!isValidString(value)) {
        return false;
    }
    // Regular expression to validate phone number with country code.
    // This regex checks for a '+' followed by digits, allowing spaces, dashes, and parentheses
    const phoneRegex = /^\+\d{1,3}[-.\s]?\(?\d+\)?([-.\s]?\d+)*$/;
    return phoneRegex.test(value);
}
