import Wortal from "../../index";
/**
 * Config for the Ads API. Holds ad unit IDs, tracks ad calls and ad impressions.
 * @hidden
 */
export class AdConfig {
    constructor() {
        this._data = {
            isAdBlocked: false,
            hasPrerollShown: false,
            interstitialId: "",
            rewardedId: "",
            bannerId: "",
            adsCalled: 0,
            adsShown: 0,
            frequencyCapS: 30,
            lastAdCallTimeMS: 0,
            isEnabled: true,
        };
    }
    /**
     * Initializes the AdConfig. This fetches ad unit IDs from a remote source if necessary.
     * @returns {Promise<void>} Promise that resolves when the AdConfig is initialized.
     * @hidden
     */
    initialize() {
        Wortal._log.debug("Initializing AdConfig..");
        Wortal._log.debug("AdConfig initialized.", this._data);
        return Promise.resolve();
    }
    get isAdBlocked() {
        return this._data.isAdBlocked;
    }
    setAdBlocked(isBlocked) {
        this._data.isAdBlocked = isBlocked;
    }
    get isEnabled() {
        return this._data.isEnabled;
    }
    setIsEnabled(isEnabled) {
        this._data.isEnabled = isEnabled;
    }
    get hasPrerollShown() {
        return this._data.hasPrerollShown;
    }
    setPrerollShown(hasShown) {
        this._data.hasPrerollShown = hasShown;
    }
    get interstitialId() {
        return this._data.interstitialId;
    }
    get rewardedId() {
        return this._data.rewardedId;
    }
    get bannerId() {
        return this._data.bannerId;
    }
    get adsCalled() {
        return this._data.adsCalled;
    }
    adCalled() {
        this._data.adsCalled++;
    }
    get adsShown() {
        return this._data.adsShown;
    }
    adShown() {
        this._data.adsShown++;
    }
    isFrequencyCapped() {
        const frequencyCapMS = this._data.frequencyCapS * 1000;
        const currentTimeMS = new Date().getTime();
        const timeSinceLastAdMS = currentTimeMS - this._data.lastAdCallTimeMS;
        return timeSinceLastAdMS < frequencyCapMS;
    }
    resetFrequencyCap() {
        this._data.lastAdCallTimeMS = new Date().getTime();
    }
}
