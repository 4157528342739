import { CoreAPI } from "./core/core-api";
import { PLATFORM_DOMAINS } from "./data/core-data";
import { getParameterByName } from "./utils/wortal-utils";
const Wortal = new CoreAPI();
window.Wortal = Wortal;
const options = {
    platform: determinePlatform(),
    debugMode: isDebugMode(),
};
Wortal._loadCoreAsync(options)
    .catch((error) => {
    console.error("SDK failed to initialize.", error);
});
function isDebugMode() {
    const scriptElement = document.currentScript;
    const debugFlag = scriptElement === null || scriptElement === void 0 ? void 0 : scriptElement.getAttribute("data-debug-mode");
    return debugFlag === "true";
}
function determinePlatform() {
    // The QA flag is used to force the platform to be debug mode as the domain will still be Wortal when using
    // the QA tool.
    if (getParameterByName("qa")) {
        window.wortalGameID = "debug";
        return "debug";
    }
    const host = window.location.host;
    for (const platform of Object.keys(PLATFORM_DOMAINS)) {
        if (PLATFORM_DOMAINS[platform].some(domain => host.includes(domain))) {
            if (platform === "wortal") {
                return parseURLParamsForPlatform();
            }
            else {
                return platform;
            }
        }
    }
    // For local testing we don't have this set from wortal-data.js, so we'll just set it to debug so that we don't
    // get any errors due to it being undefined.
    window.wortalGameID = "debug";
    return "debug";
}
function parseURLParamsForPlatform() {
    // Some builds are hosted on Wortal domain but embedded elsewhere, such as a WART bot.
    // These should include a query param with the platform name to determine the correct platform.
    if (getParameterByName("telegram_wortal")) {
        return "telegram-wortal";
    }
    else if (getParameterByName("telegram")) {
        return "telegram";
    }
    else if (getParameterByName("wortal_native")) {
        return "wortal-native";
    }
    else if (getParameterByName("telegram_bot")) {
        // This is the WART bot on Telegram. It's a special case because the normal Telegram platform
        // uses the Playdeck SDK, but WART does not. We'll initialize this as Wortal for now, but we
        // need to sort out the details of this implementation.
        //
        // Update (11/28/23): Brian says it should run as a Wortal session, so I guess we can leave this. -Tim
        return "wortal";
    }
    else if (getParameterByName("viber")) {
        return "viber";
    }
    else if (getParameterByName("gmo")) {
        return "gmo";
    }
    else {
        return "wortal";
    }
}
export default Wortal;
